import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { PortfolioDetailsData } from "@/data";
const ProtfolioDetails = () => {
  const {
    gallery,
    title,
    text,
    client,
    categories,
    date,
    socials,
  } = PortfolioDetailsData;
  return (
    <section className="commonSection porfolioDetail">
      <Container>
        <Row>
          <Col lg={6} md={5} sm={12}>
            <div className="singlePortfoio_content">
              <h3>{title}</h3>
              <p>{text}</p>
            </div>
          </Col>
          <Col lg={6} md={7} sm={12}>
            {gallery.map(({ image }, index) => (
              <div className="portDetailThumb" key={index}>
                <img alt="portDetailThumb" src={image} />
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ProtfolioDetails;
